<template>
  <div>
    <BaseList
      v-model="selectedItems"
      :headers="headers"
      :key="localKey"
      :show-select="true"
      :is-import="$can('admin')"
      :is-report="!!selectedItems.length"
      :is-filter="true"
      :query="{filter}"
      @import="handleImport"
      @report="handleReport"
      @filter="handleFilter"
      item-key="properties.id"
      :can-create="canCreatePassport"
    >
      <template #item="{item, isSelected}">
        <ListItem
          :item="item"
          :is-selected="isSelected"
          selectable
          @input="handleSelected($event)"
        />
      </template>
    </BaseList>
    <BaseReportPanel
      v-model="dialog"
      :selected-items="selectedItems"
      report-type="passport"
    />
    <!--  старая панель импорта  <PassportImportPanel v-model="importDialog" @parse:completed="handleParseComplete"/>-->
    <BaseDialog
      v-model="importDialog"
      is-cross-close
      label="Менеджер импорта"
      without-sides-padding
      little-label
      without-bot-padding
    >
      <template #subtitle>
        <div class="text-h4 font-weight-bold">Паспортов</div>
      </template>
      <template #content>
        <BaseImportPanel
          :file-type="fileType"
          :next-step-start-after="nextStepStartAfter"
          :previous-step-end-after="previousStepEndAfter"
          :params-to-upload="paramsToUpload"
          @parse:completed="handleParseComplete"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      label="Фильтр"
      :max-width="800"
      hideScroll
      v-model="isDialogFilter"
      is-cross-close
    >
      <template #content>
        <BaseFilter
          :is-empty-filter="isEmptyFilter"
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
          :value="isDialogFilter"
        >
          <template #filters>
            <FilterPassport
              @change="changeField"
              @emptyField="emptyField"
            />
          </template>
        </BaseFilter>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'
import BaseReportPanel from '@/components/base/BaseReportPanel'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import report from '@/components/mixins/report'
import importMixin from '@/components/mixins/import'
import PassportImportPanel from '@/components/views/account/passport/common/PassportImportPanel'
import BaseDialog from '@/components/base/BaseDialog'
import filter from '@/components/mixins/filter'
import FilterPassport from '@/components/views/account/passport/common/FilterPassport'
import { verificationScenarioByRole } from '@/components/utils/common'
import BaseFilter from '@/components/base/BaseFilter'
import watchFullPath from '@/components/mixins/watchFullPath'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'
import user from '@/components/mixins/user'

const PARSING_TYPE = 'passport'
const COMMAND_TYPE = 'parsing'

export default {
  mixins: [tableSelectedItems, report, importMixin, filter, watchFullPath, user],
  name: 'List',
  components: {
    BaseImportPanel,
    BaseFilter,
    BaseDialog,
    BaseReportPanel,
    ListItem,
    BaseList,
    PassportImportPanel,
    FilterPassport
  },
  data () {
    return {
      headers: headers,
      filter: {
        verificationScenario: verificationScenarioByRole(),
        ...this.$route.query.filter
      },
      localKey: 0,
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          parsingType: PARSING_TYPE
        }
      }
    }
  },
  methods: {
    handleParseComplete () {
      this.localKey++
      this.importDialog = false
    }
  },
  computed: {
    canCreatePassport() {
      return this.currentRole === 'admin' || this.currentRole === 'manager'
    }
  }
}
</script>

<style scoped>

</style>
